import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import Layout from '../components/layout'
import { Container, Jumbotron, Media, Button } from 'reactstrap'
import { eventDetails } from '../data/event';
import Breadcrumb from '../components/breadcrumb'
import { navigate } from '@reach/router';
import { Banner } from '../components/banner';
import { handleGetEventList } from '../services/api'
import { BarLoader } from 'react-spinners'
import AlertComponent from '../containers/alert';
import moment from 'moment'
import ReactGA from 'react-ga'

const override = `
    margin: 0 auto;
`

export default class events extends Component {
    constructor(props){
        super(props)

        let status = false
        let message = false
        let style = ""

        if(!props.location.state){
            status = false
            message = ""
            style = {}

        } else {
            status = props.location.state.status
            message = props.location.state.message
            style = !props.location.state.style ? "" : props.location.state.style
        }


        this.state = {
            eventData : null,
            status : status,
            statusMessage : message,
            style : style
        }

    }

    async componentDidMount(){
        let eventData = await handleGetEventList()

        this.setState({
            eventData : eventData
        })
        ReactGA.initialize(`$(process.env.GA_KEY)`);
    }


    render() {
        return (
            <EventLayout location={this.props.location}>
                <AlertComponent status={this.state.status} statusMessage={this.state.statusMessage} style={this.state.style} />
                <EventContainer data={this.state.eventData} />
            </EventLayout>

        )
    }
}

const EventContainer = (props) => {

    if(!props.data || props.data === null ){
        return(
            <div style={{textAlign:`center`}}>
                {`Loading Events...`}
                <BarLoader
                    sizeUnit={"px"}
                    width={250}
                    color={'#123abc'}
                    css={override}
                />
            </div>
        )
        
    } else {
        return props.data.map(function(value, index){
            return (
                <Jumbotron key={index}>
                    <font style={{fontSize:`1.5rem`}}>
                        {moment(value.start_date).format('DD MMMM YYYY')}
                    </font>
                    <hr className="my-2" />
                    <Media href="#" onClick={
                            event => {
                                ReactGA.event({
                                    category: "Event",
                                    action: value.title
                                });
                                event.preventDefault()
                                navigate('/events/view/?k=' + value.event_id, {state : { data : eventDetails.data[index]}})
                            }
                        }>
                        <Media src={value.main_image} style={{maxHeight: `350px`, margin:`auto`}} />
                    </Media>
                    <h4 className="display-4 text-center">
                        {value.title}
                    </h4>
                    <p className="lead">
                        {value.excerpt}
                    </p>
                    <p className="lead text-center">
                        <Button
                            size="lg"
                            color="primary"
                            onClick={
                                event => {
                                    event.preventDefault()
                                    navigate('view/?k=' + value.event_id, {state : { data : eventDetails.data[index]}})
                                }
                            }
                        >
                            Learn More
                        </Button>
                    </p>
                </Jumbotron>
            )
        })
    }
}


export const EventLayout = props => {
    return(
        <Layout>
            <Banner title={`Events`} />
            {props.location ? (<Breadcrumb loc={props.location}/>) : undefined}
            <Container
                style={{
                padding: '2rem 2rem 1rem',
                minHeight: '75vh'
                }}
            >
                {props.children}
            </Container>
      </Layout>
    )
}