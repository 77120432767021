import React, { Component } from 'react'
import { UncontrolledAlert } from 'reactstrap'

export default class AlertComponent extends Component {
    constructor(props){
        super(props)
        this.state = {
            status : this.props.status,
            statusMessage : this.props.statusMessage,
            styles : !this.props.styles ? {} : this.props.styles
        }
    }

    render() {
        return (
            this.state.status ? (
                <div>
                    <UncontrolledAlert
                        color={this.state.status}
                        style={this.state.styles}
                    >
                        {this.state.statusMessage}
                    </UncontrolledAlert>
                </div>
            ) : (
                null
            )
        )
    }
}
